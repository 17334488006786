<template>
	<div />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('companies.inviteAdmin')
		}
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.open()
		if (this.$route.query.token) {
			this.joinCompany(this.$route.query.token).then(({ success }) => {
				this.close()
				if (success) {
					this.$router.push({ name: 'Company' })
					return
				} else {
					this.$router.push({ name: 'Landing' })
				}
			})
		}
	},
	methods: {
		...mapActions('companies', ['joinCompany']),
		...mapActions('splash', ['open', 'close'])
	}
}
</script>
